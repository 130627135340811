import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Radio} from 'antd';
import { createFetchEventAddType, createFetchEventEditType} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {EventType} from '../../../constants/propTypesDefinitions';
import TimePicker from '../../general/TimePicker';
import MultiLangInput from '../../general/MultiLangInput';
const FormItem = Form.Item;

/**
 * @dusan
 */

class EventTypeForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            eventType: EventType,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, eventType ={}} = values;
                const fetchFunction = isEdit ? createFetchEventEditType() : createFetchEventAddType();
                const checkedValues = isEdit ?
                    merge(formValues, {id: eventType.id}) : formValues;
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, eventType = {}}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: eventType.name})(
                    <MultiLangInput ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Je predvolené</Trans>}
            >
                {getFieldDecorator('is_default', {initialValue: eventType.is_default})(
                    <Radio.Group>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Miesto</Trans>}
            >
                {getFieldDecorator('is_travel', {initialValue: eventType.is_travel})(
                    <Radio.Group>
                        <Radio.Button value={0}><Trans>Doma</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>U zákazníka</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Predvolené trvanie</Trans>}
            >
                {getFieldDecorator('default_duration', {initialValue: eventType.default_duration})(
                    <TimePicker
                        step={900}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(EventTypeForm);