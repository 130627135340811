import React from 'react';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {EventTypes} from '../../../constants/propTypesDefinitions';
import EventTypeEdit from './EventTypeEdit';
import EventTypeAdd from './EventTypeAdd';
import {Trans, t} from '@lingui/macro';
import EventTypeRemove from './EventTypeRemove';

/**
 * @dusan
 */

class EventTypeList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.EVENT_TYPES]: EventTypes.isRequired,
    };

    onSelect = (eventTypeId) => {
        this.setState({
            selectedId: eventTypeId,
        })
    };

    render() {
        const {selectedId} = this.state;
        const eventTypes = this.props[GLOBAL_DATA.EVENT_TYPES];
        return <List
                values={eventTypes}
                onItemClick={(value) => {
                    this.onSelect(value.id);
                }}
                selectedValueId={selectedId}
            >
                {eventTypes.map((obj = {}, index) => {
                    return <div key={index} className="d-flex flex-wrap align-items-center">
                        {obj.name}
                        {obj.id == selectedId ? [
                            <EventTypeEdit
                                key="edit"
                                eventType={obj}
                            />,
                            <EventTypeRemove
                                key="remove"
                                eventType={obj}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.EVENT_TYPES])(EventTypeList);