import React from 'react';
import Helmet from '../project/Helmet';
import EventTypeAdd from './eventTypes/EventTypeAdd';
import EventTypeList from './eventTypes/EventTypeList';
import {t} from '@lingui/macro';

/**
 * @dusan
 */

class EventTypesPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Typy eventov`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <EventTypeAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <EventTypeList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default EventTypesPage;